<template>
  <div>
    <div class="titleItem blue" @click="goBack">
      <i class="fas fa-arrow-left backBtn"></i>
      <i class="fas fa-mask" style="margin-right: 0.5rem;" />
      Privacidad
    </div>
    <div class="itemsGrid" v-if="!selectedItem">
      <div
        :class="'settingsItem ' + item.style"
        v-for="(item, itemInd) in settings"
        :key="itemInd"
        @click="selectedItem = item.id"
      >
        <i :class="item.icon" style="margin-right: 0.5rem;"></i>
        <h6>&nbsp; {{ item.name }}</h6>
      </div>
    </div>
    <div v-else-if="selectedItem === 1">
      <h3>Quién puede ... ?</h3>
      <div :class="'settingsItem green'" v-for="(item, itemInd) in events" :key="itemInd">
        <!-- <i :class="item.icon" style="margin-right: 0.5rem;"></i> -->
        <div>
          <h6>&nbsp; {{ item.name }}</h6>
        </div>
        <!-- <vs-select label="Sólo pueden..." dark>A</vs-select> -->
        <div
          style="display: flex;
    justify-content: space-between;"
        >
          <vs-button icon floating flat> <i class="fas fa-user-friends"></i> Followers</vs-button>
          <!-- <vs-button icon floating flat> <i class="fas fa-users"></i> Following</vs-button> -->
          <vs-button icon floating flat> <i class="fas fa-user-secret"></i> Nadie</vs-button>
          <vs-button icon floating flat> <i class="fas fa-globe-africa"></i> Todos</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacitySettingsFragment",
  methods: {
    goBack() {
      this.$emit("back", null);
    }
  },
  mounted() {},
  data() {
    return {
      events: [
        { name: "Enviarme un mensaje", type: null },
        { name: "Ver mi perfil", type: null },
        { name: "Ver mi contenido", type: null },
        { name: "Chatear conmigo", type: null }
      ],
      selectedItem: null,
      settings: [
        { name: "Quién puede...?", icon: "fas fa-user-circle", style: "black", id: 1 },
        { name: "Bloqueos", icon: "fas fa-user-slash", style: "black", id: 2 }
      ]
    };
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 51px;
    max-height: calc(100% - 52px);
    max-width: none;
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 100;
}

.itemsGrid {
  padding: 1rem;
  width: 100%;
  display: inline-block;
}

.titleItem {
  padding: 0.25rem;
  border-radius: 0 0 1rem 1rem;
  color: #d6e7f8;
  font-size: medium;
  filter: drop-shadow(1px 3px 8px rgb(33, 59, 75));
}

.backBtn {
  position: absolute;
  left: 1rem;
  vertical-align: sub;
}

.settingsItem {
  padding: 1rem;
  /* height: 75px; */
  width: 100%;
  /* display: inline-flex; */
  border-radius: 1rem;
  color: #d6e7f8;
  font-size: large;
  filter: drop-shadow(1px 3px 8px rgb(33, 59, 75));
  margin: 0 0 1rem 0;
}

.settingsItem.red {
  background-image: linear-gradient(
    178.2deg,
    rgba(118, 8, 23, 1) 10.9%,
    rgba(158, 12, 33, 1) 62.6%
  );
}
.settingsItem.yellow {
  background: #94964f;
  background: -webkit-linear-gradient(left, #94964f, #c4c91c);
  background: -moz-linear-gradient(left, #94964f, #c4c91c);
  background: linear-gradient(to right, #94964f, #c4c91c);
  /* color: rgba(6, 57, 84, 1); */
}

.settingsItem.blue,
.titleItem.blue {
  background-image: linear-gradient(171.8deg, rgba(5, 111, 146, 1) 13.5%, rgba(6, 57, 84, 1) 78.6%);
}

.settingsItem.green {
  background-image: linear-gradient(70.5deg, rgba(0, 81, 80, 1) 23.5%, rgba(0, 134, 132, 1) 91.3%);
}

.settingsItem.pink {
  background-image: radial-gradient(
    circle 602px at 2.1% 5.1%,
    rgba(233, 0, 120, 1) 0%,
    rgba(0, 0, 0, 1) 90.1%
  );
}

.settingsItem.gold {
  background-image: radial-gradient(
    circle 859px at 10% 20%,
    rgba(245, 220, 154, 1) 0%,
    rgba(164, 78, 51, 1) 90%
  );
  color: rgba(118, 8, 23, 1);
}

.settingsItem.black {
  background-image: radial-gradient(
    circle farthest-corner at -4% -12.9%,
    rgba(74, 98, 110, 1) 0.3%,
    rgba(30, 33, 48, 1) 90.2%
  );
}
</style>
