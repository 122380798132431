<template>
  <div v-if="activeSidebarSettings">
    <vs-sidebar class="sbMenu" v-model="active" right :open.sync="activeSidebarSettings">
      <template #logo>
        <h4>Ajustes de Cuenta</h4>
      </template>
      <div v-if="!showContentCode">
        <div class="itemsGrid">
          <div
            :class="'settingsItem ' + item.style"
            v-for="(item, itemInd) in settings"
            :key="itemInd"
            @click="showContentCode = item.code"
          >
            <i :class="item.icon"></i>
            <h6>{{ item.name }}</h6>
          </div>
        </div>
        <vs-button transparent @click="logout">
          <i class="fas fa-sign-out-alt" style="margin-right: 0.5rem;" />
          Cerrar sesión
        </vs-button>
        <!-- <div class="settingsItemLO" @click="logout">
          <i class="fas fa-sign-out-alt" style="margin-right: 0.5rem;"></i>
          Log Out
        </div> -->
      </div>
      <div v-else style="width:100%">
        <CustomAccount @back="backToMain" v-if="showContentCode === 1" />
        <SecuritySettings @back="backToMain" v-if="showContentCode === 2" />
        <PrivacitySettings @back="backToMain" v-if="showContentCode === 3" />
        <NotificationsSettings @back="backToMain" v-if="showContentCode === 4" />
        <HelpSettings @back="backToMain" v-if="showContentCode === 5" />
        <InfoSettings @back="backToMain" v-if="showContentCode === 6" />
      </div>

      <template #footer>
        <!-- <vs-button block success @click="onSave"> <i class="fas fa-save"></i> Save </vs-button> -->
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>Close
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, authMix } from "@/common/mixin.js";
import CustomAccount from "@/components/SIDEBARS/Fragments/CustomAccount.vue";
import HelpSettings from "@/components/SIDEBARS/Fragments/HelpSettings.vue";
import InfoSettings from "@/components/SIDEBARS/Fragments/InfoSettings.vue";
import NotificationsSettings from "@/components/SIDEBARS/Fragments/NotificationsSettings.vue";
import PrivacitySettings from "@/components/SIDEBARS/Fragments/PrivacitySettings.vue";
import SecuritySettings from "@/components/SIDEBARS/Fragments/SecuritySettings.vue";
import { mapState } from "vuex";

export default {
  name: "accountSettingsSB",
  mixins: [loadingMix, noficationsDialog, authMix],
  methods: {
    backToMain() {
      this.showContentCode = null;
    },

    onSave() {
      const { isPrivate, password, edad, genero, pais, direccion, servicio, postalCode } = this;
      const { username, nombre, apellidos, email } = this.profile;

      const data = {
        isPrivate,
        nombre,
        apellidos,
        username,
        password,
        email,
        edad,
        genero,
        pais,
        direccion,
        servicio,
        postalCode
      };
      this.onCloseSB();
      this.$emit("onSave", data);
    },

    onCloseSB() {
      this.$emit("onSave", null);
      this.$store.commit("showAccountSettingsSB", false);
      this.showContentCode = null;
      this.activeSidebarSettings = false;
    },
    async logout() {
      this.firebaseLogout();
      this.$store.commit("setUser", {});
      localStorage.removeItem("user");
      localStorage.setItem("privacity", true);
      this.$router.push("/");
    }
  },
  components: {
    CustomAccount,
    HelpSettings,
    InfoSettings,
    NotificationsSettings,
    PrivacitySettings,
    SecuritySettings
  },
  computed: mapState(["showAccountSettingsSB"]),
  watch: {
    showAccountSettingsSB(newValue, oldValue) {
      this.activeSidebarSettings = newValue;
    }
  },
  mounted() {
    if (this.$store.state.currentUser) {
      const { username, nombre, apellidos, email } = this.$store.state.currentUser;
      this.profile = { username, nombre, apellidos, email };
    }
  },
  data() {
    return {
      variant: "info",
      active: "test2",
      profile: null,
      activeSidebarSettings: this.$store.state.showAccountSettingsSB || false,
      modalShow: true,
      type: 1,
      showContentCode: null,
      settings: [
        { name: "Personalizar cuenta", icon: "fas fa-user-astronaut", style: "red", code: 1 },
        { name: "Seguridad", icon: "fas fa-user-shield", style: "yellow", code: 2 },
        { name: "Privacidad", icon: "fas fa-mask", style: "blue", code: 3 },
        { name: "Notificaciones", icon: "fas fa-bell", style: "green", code: 4 },
        { name: "Ayuda", icon: "fas fa-question-circle", style: "pink", code: 5 },
        { name: "Acerca de BYP", icon: "fas fa-info-circle", style: "black", code: 6 }
      ]
    };
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 51px;
    max-height: calc(100% - 52px);
    max-width: none;
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 100;
}

.itemsGrid {
  padding: 1rem;
  width: 100%;
  display: block;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1% 2%;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."; */
}

.settingsItemLO {
  height: 30px;
  margin: auto;
  width: 75%;
  border-radius: 0.5rem;
  color: #d6e7f8;
  font-size: large;
  filter: drop-shadow(1px 3px 8px rgb(33, 59, 75));
  background-image: radial-gradient(
    circle farthest-corner at 50.4% 50.5%,
    rgba(251, 32, 86, 1) 0%,
    rgba(135, 2, 35, 1) 90%
  );
}

.settingsItem {
  padding: 1rem;
  /* height: 150px; */
  border-radius: 2rem;
  color: #d6e7f8;
  display: flex;
  /* font-size: xxx-large; */
  /* filter: drop-shadow(1px 3px 8px rgb(33, 59, 75)); */
}

.settingsItem.red {
  border-color: rgba(158, 12, 33, 1);
  color: rgba(118, 8, 23, 1);
  /* background-image: linear-gradient(
    178.2deg,
    rgba(118, 8, 23, 1) 10.9%,
    rgba(158, 12, 33, 1) 62.6%
  ); */
}
.settingsItem.yellow {
  color: #94964f;
  border-color: #94964f;
  /* background: #94964f;
  background: -webkit-linear-gradient(left, #94964f, #c4c91c);
  background: -moz-linear-gradient(left, #94964f, #c4c91c);
  background: linear-gradient(to right, #94964f, #c4c91c); */
  /* color: rgba(6, 57, 84, 1); */
}

.settingsItem.blue {
  border-color: rgba(6, 57, 84, 1);
  color: rgba(6, 57, 84, 1);
  /* background-image: linear-gradient(171.8deg, rgba(5, 111, 146, 1) 13.5%, rgba(6, 57, 84, 1) 78.6%); */
}

.settingsItem.green {
  color: rgba(0, 81, 80, 1);
  border-color: rgba(0, 81, 80, 1);
  /* background-image: linear-gradient(70.5deg, rgba(0, 81, 80, 1) 23.5%, rgba(0, 134, 132, 1) 91.3%); */
}

.settingsItem.pink {
  /* background-image: radial-gradient(
    circle 602px at 2.1% 5.1%,
    rgba(233, 0, 120, 1) 0%,
    rgba(0, 0, 0, 1) 90.1%
  ); */
  color: black;
}

.settingsItem.gold {
  /* background-image: radial-gradient(
    circle 859px at 10% 20%,
    rgba(245, 220, 154, 1) 0%,
    rgba(164, 78, 51, 1) 90%
  ); */
  color: rgba(118, 8, 23, 1);
}

.settingsItem.black {
  /* background-image: radial-gradient(
    circle farthest-corner at -4% -12.9%,
    rgba(74, 98, 110, 1) 0.3%,
    rgba(30, 33, 48, 1) 90.2%
  ); */
  color: rgba(30, 33, 48, 1);
}
</style>
