<template>
  <div>
    <div class="titleItem green" @click="goBack">
      <i class="fas fa-arrow-left backBtn"></i>
      <i class="fas fa-info-circle" style="margin-right: 0.5rem;" />Notificaciones
    </div>
    <div class="itemsGrid">
      <div class="header">
        <b> Evento</b>
        <div>
          <!-- Notificaciones -->
          <i class="fas fa-bell"></i>
          <!-- Email -->
          <i class="fas fa-envelope"></i>
        </div>
      </div>

      <div :class="'settingsItem green'" v-for="(item, itemInd) in events" :key="itemInd">
        <!-- <i :class="item.icon" style="margin-right: 0.5rem;"></i> -->
        <h6>&nbsp; {{ item.name }}</h6>
        <div
          style="display: flex;
    justify-content: space-between;"
        >
          <vs-switch success v-model="item.push">
            <template #off>
              <i class="fas fa-volume-mute"></i>
            </template>
            <template #on>
              <i class="fas fa-bell"></i>
            </template>
          </vs-switch>
          <vs-switch success v-model="item.mail">
            <template #off>
              <i class="fas fa-bell-slash"></i>
            </template>
            <template #on>
              <i class="fas fa-envelope"></i>
            </template>
          </vs-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notificationSettingsFragment",
  methods: {
    goBack() {
      this.$emit("back", null);
    }
  },
  mounted() {},
  data() {
    return {
      events: [
        { name: "Nuevo follower", mail: false, push: false },
        { name: "Nuevo participante", mail: false, push: false },
        { name: "Nuevo contenido", mail: false, push: false },
        { name: "Mensajes", mail: false, push: false },
        { name: "Recordatorios de eventos", mail: false, push: false }
      ]
    };
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 51px;
    max-height: calc(100% - 52px);
    max-width: none;
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.75rem 0 2rem;
}
.header > div {
  width: 48%;
  display: flex;
  justify-content: space-evenly;
}

.itemsGrid {
  padding: 1rem;
  width: 100%;
  display: inline-block;
}

.titleItem {
  padding: 0.25rem;
  border-radius: 0 0 1rem 1rem;
  color: #d6e7f8;
  font-size: medium;
  filter: drop-shadow(1px 3px 8px rgb(33, 59, 75));
}

.backBtn {
  position: absolute;
  left: 1rem;
  vertical-align: sub;
}

.settingsItem {
  padding: 1rem;
  /* height: 75px; */
  width: 100%;
  display: inline-flex;
  border-radius: 1rem;
  color: #d6e7f8;
  font-size: large;
  filter: drop-shadow(1px 3px 8px rgb(33, 59, 75));
  margin: 0 0 1rem 0;
  justify-content: space-between;
}

.settingsItem.red {
  background-image: linear-gradient(
    178.2deg,
    rgba(118, 8, 23, 1) 10.9%,
    rgba(158, 12, 33, 1) 62.6%
  );
}
.settingsItem.yellow {
  background: #94964f;
  background: -webkit-linear-gradient(left, #94964f, #c4c91c);
  background: -moz-linear-gradient(left, #94964f, #c4c91c);
  background: linear-gradient(to right, #94964f, #c4c91c);
  /* color: rgba(6, 57, 84, 1); */
}

.settingsItem.blue {
  background-image: linear-gradient(171.8deg, rgba(5, 111, 146, 1) 13.5%, rgba(6, 57, 84, 1) 78.6%);
}

.settingsItem.green,
.titleItem.green {
  background-image: linear-gradient(70.5deg, rgba(0, 81, 80, 1) 23.5%, rgba(0, 134, 132, 1) 91.3%);
}

.settingsItem.pink {
  background-image: radial-gradient(
    circle 602px at 2.1% 5.1%,
    rgba(233, 0, 120, 1) 0%,
    rgba(0, 0, 0, 1) 90.1%
  );
}

.settingsItem.gold {
  background-image: radial-gradient(
    circle 859px at 10% 20%,
    rgba(245, 220, 154, 1) 0%,
    rgba(164, 78, 51, 1) 90%
  );
  color: rgba(118, 8, 23, 1);
}

.settingsItem.black {
  background-image: radial-gradient(
    circle farthest-corner at -4% -12.9%,
    rgba(74, 98, 110, 1) 0.3%,
    rgba(30, 33, 48, 1) 90.2%
  );
}
</style>
