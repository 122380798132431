<template>
  <div v-if="activeSidebar">
    <vs-sidebar class="sbMenu" v-model="active" right :open.sync="activeSidebar">
      <!-- :open.sync="activeSidebar" -->
      <template #logo>
        <!-- <img :src="require('@/assets/android-chrome-512x512_green.png')" /> -->
        <h4>Ajustes de perfil</h4>
      </template>
      <vs-sidebar-item>
        <vs-switch class="inputs" color="#056877" v-model="isPrivate">
          <template #off> Publico</template>
          <template #on> Privado <i class="fa fa-block" aria-hidden="true"></i> </template>
        </vs-switch>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          placeholder="Nombre"
          :color="profile.nombre !== '' ? 'success' : '#fff'"
          label="Nombre"
          v-model="profile.nombre"
        >
          <template v-if="profile.nombre === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          v-if="this.type < 2"
          label="Apellidos"
          :color="profile.apellidos !== '' ? 'success' : '#fff'"
          placeholder="Apellidos"
          v-model="profile.apellidos"
        >
          <template v-if="profile.apellidos === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          :color="profile.username !== '' ? 'success' : '#fff'"
          label="Username"
          placeholder="Username"
          v-model="profile.username"
        >
          <template v-if="profile.username === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          type="password"
          :color="password !== '' ? 'success' : '#fff'"
          v-model="password"
          label="Password"
          placeholder="Password"
          :visiblePassword="hasVisiblePassword"
          icon-after
          @click-icon="hasVisiblePassword = !hasVisiblePassword"
        >
          <template v-if="password === ''" #message-danger>
            Required
          </template>
          <!-- <template v-if="getProgress >= 100" #message-success>
            Secure password
          </template> -->
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          type="password"
          :color="password === password2 ? 'success' : '#fff'"
          v-model="password2"
          label="Repeat Password"
          placeholder="Repeat Password"
          :visiblePassword="hasVisiblePassword"
          icon-after
          @click-icon="hasVisiblePassword = !hasVisiblePassword"
        >
          <template v-if="password2 === '' || password !== password2" #message-danger>
            {{ password2 !== password && password2 !== "" ? "Invalid password" : "Required" }}
          </template>
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          :color="profile.email !== '' ? 'success' : '#fff'"
          label="Email"
          placeholder="Email"
          v-model="profile.email"
        >
          <template v-if="profile.email === ''" #message-danger>
            Required
          </template>
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          type="number"
          v-if="this.type < 2"
          label="Indicanos tu edad"
          placeholder="Indicanos tu edad"
          v-model="edad"
        />
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-select
          :label="'Indicanos tu genero'"
          placeholder="Indicanos tu genero"
          v-model="genero"
        >
          <vs-option v-for="(gender, i) in generos" :key="i" :label="gender" :value="gender">
            {{ gender }}
          </vs-option>
        </vs-select>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-select :label="'Indicanos tu pais'" placeholder="Indicanos tu pais" v-model="pais">
          <vs-option v-for="(pais, i) in paises" :key="i" :label="pais" :value="pais">
            {{ pais }}
          </vs-option>
        </vs-select>
        <!-- <b-form-select :options="paises" v-model="pais"></b-form-select> -->
      </vs-sidebar-item>
      <vs-sidebar-item v-if="this.type === 2">
        <vs-input label="Direccion" placeholder="Direccion" v-model="direccion"></vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item v-if="this.type === 2">
        <vs-input label="Servicio" placeholder="Servicio" v-model="servicio"></vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          :color="postalCode !== '' ? 'success' : '#fff'"
          label="Codigo Postal"
          placeholder="Codigo Postal"
          v-model="postalCode"
        />
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          label="Intereses"
          placeholder="Intereses"
          list="dropdown"
          v-model="interes"
          v-on:keyup.enter="addInteres"
        >
          Ordena tus intereses por preferencia
        </vs-input>
      </vs-sidebar-item>
      <template #footer>
        <vs-button block success @click="onSave"> <i class="fas fa-save"></i> Save </vs-button>
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>Close
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "userSettingsSB",
  computed: {
    getProgress() {
      let progress = 0;
      // at least one number
      if (/\d/.test(this.password)) {
        progress += 20;
      }
      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.password)) {
        progress += 20;
      }
      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.password)) {
        progress += 20;
      }
      // more than 5 digits
      if (this.password.length >= 6) {
        progress += 20;
      }
      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.password)) {
        progress += 20;
      }
      return progress;
    }
  },
  methods: {
    onSave() {
      const { isPrivate, password, edad, genero, pais, direccion, servicio, postalCode } = this;

      const { username, nombre, apellidos, email } = this.profile;

      const data = {
        isPrivate,
        nombre,
        apellidos,
        username,
        password,
        email,
        edad,
        genero,
        pais,
        direccion,
        servicio,
        postalCode
      };
      this.onCloseSB();
      this.$emit("onSave", data);
    },

    onCloseSB() {
      this.$store.commit("showUserSettingsSB", false);
      this.$emit("onEditParent", true);
      this.activeSidebar = false;
    },

    addInteres() {
      this.intereses.push({ name: this.interes, rating: null });
      this.interes = "";
    }
  },
  computed: mapState(["showUserSettingsSB"]),
  watch: {
    showUserSettingsSB(newValue, oldValue) {
      this.activeSidebar = newValue;
    }
  },
  mounted() {
    const { username, nombre, apellidos, email } = this.$store.state.currentUser;
    if (this.$store.state.currentUser) {
      this.profile = { username, nombre, apellidos, email };
    }
  },
  data() {
    return {
      variant: "info",
      active: "test",
      profile: null,
      activeSidebar: this.$store.state.showUserSettingsSB || false,
      password: "",
      password2: "",
      direccion: "",
      servicio: "",
      modalShow: true,
      type: 1,
      edad: "",
      postalCode: "",
      pais: "España",
      genero: "Masculino",
      intereses: [],
      interes: "",
      isPrivate: false,
      paises: ["Andorra", "España", "Senegal", "Rusia", "Zimbawe"],
      generos: ["Masculino", "Femenino", "Trans"],
      hasVisiblePassword: false
    };
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 78px);
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 100;
}
</style>
